import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Button, Container, Row } from "reactstrap";

// core components

function Examples() {
  return (
    <>
      <div className="section section-examples" data-background-color="black">
        <div className="space-50"></div>
        <Container className="text-center">
          <Row>
            <div className="col">
              <a href="cwm.naturs.co.za" target="_blank">
                <img
                  alt="..."
                  className="img-raised"
                  src={require("assets/img/applogo.jpg")}
                ></img>
              </a>
              <Button
                className="btn-round"
                color="default"
                to="/landing-page"
                outline
                tag={Link}
              >
                Client Wealth Management
              </Button>
            </div>
            <div className="col">
              <a href="answers.naturs.co.za" target="_blank">
                <img
                  alt="..."
                  className="img-raised"
                  src={require("assets/img/applogo.jpg")}
                ></img>
              </a>
              <Button
                className="btn-round"
                color="default"
                to="/landing-page"
                outline
                tag={Link}
              >
                Orion
              </Button>
            </div>
            <div className="col">
              <a href="listmail.naturs.co.za" target="_blank">
                <img
                  alt="..."
                  className="img-raised"
                  src={require("assets/img/applogo.jpg")}
                ></img>
              </a>
              <Button
                className="btn-round"
                color="default"
                to="/landing-page"
                outline
                tag={Link}
              >
                Mail Listing
              </Button>
            </div>
            <div className="col">
              <a href="net.naturs.co.za" target="_blank">
                <img
                  alt="..."
                  className="img-raised"
                  src={require("assets/img/applogo.jpg")}
                ></img>
              </a>
              <Button
                className="btn-round"
                color="default"
                to="/landing-page"
                outline
                tag={Link}
              >
                Personal Finance manager
              </Button>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Examples;
