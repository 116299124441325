import React from "react";

import { Link } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";

// core components

function Images() {

  const [iconPills, setIconPills] = React.useState("1");
  const [pills, setPills] = React.useState("1");


  return (
    <>
      <div className="section section-images">
        <Container style={{maxWidth: '1500px'}}>
          <Row>
            <Col md="12">
            <Card>
            <h2 className="title" style={{marginLeft: '40px'}} >Control Panel</h2>
                <CardHeader>
                  <Nav className="justify-content-center" role="tablist" tabs>
                    <NavItem>
                      <NavLink
                        className={iconPills === "1" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("1");
                        }}
                      >
                        
                        Dashboard
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={iconPills === "2" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("2");
                        }}
                      >
                        
                        Applications
                      </NavLink>
                    </NavItem>
                    
                    <NavItem>
                      <NavLink
                        className={iconPills === "4" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("4");
                        }}
                      >
                        Account
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody>
                  <TabContent
                    className="text-center"
                    activeTab={"iconPills" + iconPills}
                  >
                    <TabPane tabId="iconPills1">
                    <Row className="justify-content-md-center">
                      <Col className="text-center" lg="8" md="12">
                        <h3 className="title">No New Notifications</h3>
                        <h5 className="description">
                          Naturs Systems
                        </h5>
                      </Col>
                      
                      
                    </Row>
                    </TabPane>
                    <TabPane tabId="iconPills2">
                    <Container className="text-center">
                        <Row>
                          <div className="col">
                            <a href="https://cwm.naturs.co.za" target="_blank">
                              <img
                                alt="..."
                                className="img-raised"
                                src={require("assets/img/applogo.jpg")}
                              ></img>
                            </a>
                            <Button
                              className="btn-round"
                              color="default"
                              to="https://cwm.naturs.co.za"
                              outline
                              tag={Link}
                              
                            >
                              Client Wealth Management
                            </Button>
                          </div>
                          <div className="col">
                            <a href="https://orion.naturs.co.za" target="_blank">
                              <img
                                alt="..."
                                className="img-raised"
                                src={require("assets/img/applogo.jpg")}
                              ></img>
                            </a>
                            <Button
                              className="btn-round"
                              color="default"
                              to="https://orion.naturs.co.za"
                              outline
                              tag={Link}
                            >
                              Orion
                            </Button>
                          </div>
                          <div className="col">
                            <a href="https://listmail.naturs.co.za" target="_blank">
                              <img
                                alt="..."
                                className="img-raised"
                                src={require("assets/img/applogo.jpg")}
                              ></img>
                            </a>
                            <Button
                              className="btn-round"
                              color="default"
                              to="https://listmail.naturs.co.za"
                              outline
                              tag={Link}
                            >
                              Mail Listing
                            </Button>
                          </div>
                          <div className="col">
                            <a href="https://net.naturs.co.za" target="_blank">
                              <img
                                alt="..."
                                className="img-raised"
                                src={require("assets/img/applogo.jpg")}
                              ></img>
                            </a>
                            <Button
                              className="btn-round"
                              color="default"
                              to="https://net.naturs.co.za"
                              outline
                              tag={Link}
                            >
                              Personal Finance manager
                            </Button>
                          </div>
                        </Row>
                      </Container>
                    </TabPane>
                    
                    <TabPane tabId="iconPills4">
                      <p>
                        Under Consrtuction
                      </p>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Images;
