import React from "react";
import { Container, Button } from "reactstrap";

function IndexHeader() {
  let pageHeader = React.createRef();
  const [isLoggedIn, setIsLoggedIn] = React.useState(sessionStorage.getItem("isLoggedIn"));
  const [companyName, setCompanyName] = React.useState(sessionStorage.getItem("companyName"));

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  }, []);

  const handleLogout = () => {
    sessionStorage.removeItem("isLoggedIn");
    setIsLoggedIn(false);
  };

  return (
    <>
      <div className="page-header clear-filter" filter-color="white">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/naturs-back.jpg") + ")"
          }}
          ref={pageHeader}
        ></div>
        <Container>
        {isLoggedIn ? (
              <>
              <div className="content-center brand">
                <img
                  alt="..."
                  className="n-logo"
                  src={require("assets/img/naturslogo-alpha.png")}
                ></img>
                <h1 className="h1-seo text-black">{companyName}.</h1>
                <h3 className="text-black">Welcome</h3>
              </div>
              <h6 className="category category-absolute text-white">
                Built by {"Native Silicon"}
                <a href="http://nativesi.co.za/?ref=naturs-systems" target="_blank">
                  <img
                    alt="..."
                    className="invision-logo"
                    src={require("assets/img/Logo-alt-alpha.png")}
                  ></img>
                </a>
                .
              </h6>
              </>
            ) : (
              
              <>
              <div className="content-center brand">
                <img
                  alt="..."
                  className="n-logo"
                  src={require("assets/img/naturslogo-alpha.png")}
                ></img>
                <h1 className="h1-seo text-black">Naturs Systems.</h1>
                <h3 className="text-black">Service Manager.</h3>
              </div>
              <h6 className="category category-absolute text-white">
                Built by {"Native Silicon"}
                <a href="http://nativesi.co.za/?ref=naturs-systems" target="_blank">
                  <img
                    alt="..."
                    className="invision-logo"
                    src={require("assets/img/Logo-alt-alpha.png")}
                  ></img>
                </a>
                .
              </h6>
              </>
            )}
          
        </Container>
      </div>
    </>
  );
}

export default IndexHeader;
